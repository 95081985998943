import Vue from "vue";
import VueRouter from "vue-router";
// import routerList from "./power";
const routerList = require("./power");
import store from "@/store";
const layout = () => import("@/layout/Layout.vue");
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/loginMassage",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/result/404.vue"),
  },
  // 酒店登录页
  {
    path: "/loginMassage",
    name: "loginMassage",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/loginFile/loginMassage.vue"
      ),
  },
  // 商家协议页面
  {
    path: "/registationAgreement",
    name: "registationAgreement",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/loginFile/registationAgreement.vue"
      ),
  },

  // 入驻流程
  {
    path: "/signInProcess",
    name: "signInProcess",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/loginFile/signInProcess.vue"
      ),
  },

  // 酒店入驻
  // {
  //   path: "/hotelCheckIn",
  //   name: "hotelCheckIn",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "login" */ "@/views/loginFile/hotelCheckIn/hotelCheckIn.vue"
  //     ),
  // },
  // 酒店入驻-审核状态
  // {
  //   path: "/hotelCheckInAuditStatus",
  //   name: "hotelCheckInAuditStatus",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "login" */ "@/views/loginFile/hotelCheckIn/hotelCheckInAuditStatus.vue"
  //     ),
  // },

  // 酒店入驻-快捷
  {
    path: "/hotelCheckIn",
    name: "hotelCheckIn",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/loginFile/quickHotelCheckIn/hotelCheckIn.vue"
      ),
  },
  // 酒店入驻-审核状态-快捷
  {
    path: "/hotelCheckInAuditStatus",
    name: "hotelCheckInAuditStatus",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/loginFile/quickHotelCheckIn/hotelCheckInAuditStatus.vue"
      ),
  },

  {
    path: "home",
    component: layout,
    children: [
      //测试图片管理里面某个模块为空的页面
      {
        path: "/asasas",
        name: "asasas",
        component: () =>
          import(
            /* webpackChunkName: "home" */
            "@/views/informationManagement/jdxxFlies/picType/defaultPages.vue"
          ),
      },
      //信息管理——酒店信息
      {
        path: "/inforManage/hotelInformation",
        name: "hotelInformation",
        component: () =>
          import(
            /* webpackChunkName: "inforManage" */ "@/views/informationManagement/hotelinformation/Index.vue"
          ),
      },

      //信息管理-酒店信息-门店信息
      {
        path: "/inforManage/storeinformation",
        name: "storeInformation",
        component: () =>
          import(
            /* webpackChunkName: "inforManage" */
            "@/views/informationManagement/jdxxFlies/hotelInformation.vue"
          ),
      },

      //信息管理-酒店亮点
      {
        path: "/inforManage/hotelhighlights",
        name: "hotelhighlights",
        component: () =>
          import(
            /* webpackChunkName: "inforManage" */ "@/views/informationManagement/hotelhighlights/Index.vue"
          ),
      },

      //订单管理-接单大厅
      {
        path: "/orderManage/receptionHall",
        name: "receptionHall",
        meta: { name: "接单大厅" },
        component: () =>
          import(
            /* webpackChunkName: "orderManage" */ "@/views/orderManage/receptionHall/Index.vue"
          ),
      },
      //订单管理-接单大厅-我的订单
      {
        path: "/orderManage/myOrder",
        name: "myOrder",
        meta: { name: "我的订单", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "orderManage" */ "@/views/orderManage/receptionHall/Myorder.vue"
          ),
      },

      //订单管理-自动接单
      {
        path: "/orderManage/automaticOrderAcceptance",
        name: "automaticOrderAcceptance",
        meta: { name: "自动接单" },
        component: () =>
          import(
            /* webpackChunkName: "orderManage" */ "@/views/orderManage/automaticOrderAcceptance/Index.vue"
          ),
      },
      //订单管理-我的订单
      {
        path: "/orderManage/myOrderManagement",
        name: "myOrderManagement",
        meta: { name: "我的订单" },
        component: () =>
          import(
            /* webpackChunkName: "orderManage" */ "@/views/orderManage/myOrder/Index.vue"
          ),
      },
      //订单管理-历史订单
      {
        path: "/orderManage/historicalOrders",
        name: "historicalOrders",
        meta: { name: "历史订单", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "orderManage" */ "@/views/orderManage/myOrder/Historicalorders.vue"
          ),
      },

      // 系统管理-店铺管理
      {
        path: "/systemManage/storeManagement",
        name: "storeManagement",
        meta: { name: "店铺管理" },
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/storeManagementFile/storeManagement.vue"
          ),
      },
      // 系统管理-店铺管理-新增/编辑店铺
      {
        path: "/systemManage/addStore",
        name: "addStore",
        meta: { name: "新增店铺", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/storeManagementFile/addStore.vue"
          ),
      },
      // 系统管理-店铺管理-酒店详情
      {
        path: "/systemManage/storeDetails",
        name: "storeDetails",
        meta: { name: "酒店详情", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/storeManagementFile/storeDetails.vue"
          ),
      },
      // 系统管理-店铺管理-操作记录
      {
        path: "/systemManage/storeOperation",
        name: "storeOperation",
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/storeManagementFile/storeOperation.vue"
          ),
      },
      // 系统管理-角色管理
      {
        path: "/systemManage/roleManagement",
        name: "roleManagement",
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/roleManagementFile/roleManagement.vue"
          ),
      },
      // 系统管理-成员管理
      {
        path: "/systemManage/menberManagement",
        name: "menberManagement",
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/memberManagementFile/menberManagement.vue"
          ),
      },
      // 系统管理-修改密码
      {
        path: "/systemManage/changePassword",
        name: "changePassword",
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/changePasswordFile/changePassword.vue"
          ),
      },
      // 系统管理-账号管理
      {
        path: "/systemManage/accountNumber",
        name: "accountNumber",
        meta: { name: "账号管理" },
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */ "@/views/systemAdministration/accountNumber/index.vue"
          ),
      },
      // 系统管理-账号新增
      {
        path: "/systemManage/changeAccount",
        name: "changeAccount",
        meta: { name: "账号新增", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/accountNumber/changeAccount.vue"
          ),
      },
      // 系统管理-账号详情
      {
        path: "/systemManage/accountInfo",
        name: "accountInfo",
        meta: { name: "账号详情", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */ "@/views/systemAdministration/accountNumber/accountInfo.vue"
          ),
      },
      // 系统管理-操作日志
      {
        path: "/systemManage/operationRecord",
        name: "operationRecord",
        meta: { name: "操作日志", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "systemManage" */
            "@/views/systemAdministration/accountNumber/operationRecord.vue"
          ),
      },

      // 工作中心-工作中心
      {
        path: "/workCenter/workcenter",
        name: "workcenter",
        component: () =>
          import(
            /* webpackChunkName: "workCenter" */ "@/views/workCenter/workCenter/Index.vue"
          ),
      },
      // 工作中心-消息
      {
        path: "/workCenter/info",
        name: "info",
        component: () =>
          import(
            /* webpackChunkName: "workCenter" */ "@/views/workCenter/workCenter/Info.vue"
          ),
      },

      // 房态房量-房态房量
      {
        path: "/housePrice/roomStatusAndQuantity",
        name: "roomStatusAndQuantity",
        meta: { name: "房态房量" },
        component: () =>
          import(
            /* webpackChunkName: "housePrice" */
            "@/views/roomStatusAndQuantity/roomStatusAndQuantity/Index.vue"
          ),
      },
      // 房态房量-节假日
      {
        path: "/housePrice/holidayAndVacations",
        name: "holidayAndVacations",
        meta: { name: "节假日" },
        component: () =>
          import(
            /* webpackChunkName: "housePrice" */ "@/views/roomStatusAndQuantity/holidayAndVacations/Index.vue"
          ),
      },
      // 房态房量-节假日-新增
      {
        path: "/housePrice/addHolidayAndVacations",
        name: "addHolidayAndVacations",
        meta: { name: "节假日新增", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "housePrice" */ "@/views/roomStatusAndQuantity/holidayAndVacations/Add.vue"
          ),
      },
      // 房态房量-房价列表
      {
        path: "/housePrice/housePriceList",
        name: "housePriceList",
        meta: { name: "房价管理" },
        component: () =>
          import(
            /* webpackChunkName: "housePrice" */
            "@/views/roomStatusAndQuantity/housePriceManagement/housePriceList.vue"
          ),
      },
      // 房态房量-房价新增
      {
        path: "/housePrice/housePriceManagement",
        name: "housePriceManagement",
        meta: { name: "房价新增/编辑", isShowBreadcrumb: true },
        component: () =>
          import(
            /* webpackChunkName: "housePrice" */ "@/views/roomStatusAndQuantity/housePriceManagement/Add.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let isLogin = JSON.parse(sessionStorage.getItem("vuex"))?.user?.token;
  // console.log(to);
  if (to.path != "/loginMassage") {
    if (isLogin) {
      // 路由权限判断
      let powerList = JSON.parse(localStorage.getItem("powerList"));
      routerList.list = routerList.list.concat(powerList);
      if (!routerList?.list.some((item) => to.path.includes(item))) {
        next("/loginMassage");
      } else {
        next();
      }
      // 判断是否为主账号
      // if (
      //   to.path == "/systemManage/storeManagement" &&
      //   !store.getters.userInfo.isMainAccount
      // ) {
      //   next("/404");
      // }
      // let noCanPath = "/personCenter";
      // if (to.path == noCanPath) {
      //   if (localStorage.getItem("uesr111")) {
      //     next();
      //   } else {
      //     next("/homePage");
      //   }
      // } else {
      //   next();
      // }
    } else {
      next("/loginMassage");
    }
  } else {
    next();
  }
});
// 重写push
let originPush = VueRouter.prototype.push; //备份原push方法
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
export default router;
