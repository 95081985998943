import hotelApi from "@/api/jiekou";
const state = {
  //初始化 从本地拉取数据赋值给vuex
  token: localStorage.getItem("setToken"),
  role: localStorage.getItem("role"),
  userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}, //用户信息
  powerList: JSON.parse(localStorage.getItem("powerList")) || [], //用户路由权限
};
const mutations = {
  SET_TOKEN(state, data) {
    //token存vuex
    state.token = data;
    //token存本地
    localStorage.setItem("setToken", data);
  },
  SET_ROLE(state, data) {
    //role存vuex
    state.role = data;
    //role存本地
    localStorage.setItem("role", data);
  },
  SET_USERINFO(state, data) {
    state.userInfo = data;
  },
  SET_POWERLIST(state, data) {
    state.powerList = data;
  },
  A_CLEAR(state) {
    //清空vuex
    state.userInfo = {};
    state.token = "";
    state.role = "";

    //清空本地
    localStorage.clear();
  },
};

const actions = {
  //登录--获取token再存
  A_login({ commit }, data) {
    //凡是 actions里面有异步 必须包一层Promise壳子
    return new Promise(async (resolve) => {
      //发送请求
      let res = await hotelApi.loginApi(data);
      let { code, data } = res;
      if (code === 200) {
        commit("SET_TOKEN", "Bearer " + data);
        // commit("SET_ROLE", role);
        //切换promise状态为 已成功
        resolve();
      }
    });
  },
  //获取个人信息
  A_userInfo({ commit }) {
    return new Promise(async (resolve) => {
      let res = await hotelApi.getHotelBusinessInfoApi();
      let { status, data } = res;
      if (status === 200) {
        commit("SET_USERINFO", data);
        resolve();
      }
    });
  },
  //清除个人信息
  A_clear({ commit }) {
    return new Promise((resolve) => {
      commit("A_CLEAR");
      resolve();
    });
  },
};
export default {
  //命名空间
  namespaced: true,
  state,
  mutations,
  actions,
};
