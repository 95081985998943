<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Management from "@/layout/Layout.vue";
import LoginMassage from "@/views/loginFile/loginMassage.vue";
export default {
  name: "App",
  components: {
    Management,
    LoginMassage,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
.w100 {
  width: 100%;
}
</style>
<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.mainHeader {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 16.5px;

  > div {
    width: 100%;
  }

  img {
    width: 20px;
    height: 20px;
  }

  .headerTitle {
    margin: 17px 31px 17px 0;
  }

  .headerBtnList {
    display: flex;
    width: 50%;
    justify-content: end;
    margin: 17px 24px 17px 0;
    position: relative;

    .numDing {
      position: absolute;
      right: 122px;
      top: -6px;
    }

    div {
      width: 100px;

      .item {
        width: 20px;
      }
    }

    // .ding {
    //   position: absolute;
    //   top: -22px;
    //   right: 300px;
    // }
    .out {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -14px;
    }

    .userPic {
      width: 30%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;

      span {
        position: absolute;
        top: 1px;
        right: -21px;
      }
    }

    .zhPic {
      padding-left: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      position: relative;

      .zhImg {
        position: absolute;
        right: 0;
        top: 4px;
      }
    }
  }
}

:deep .el-container {
  height: 100%;
  width: 100%;

  .container {
    height: calc(100% - 60px);

    .el-main {
      background-color: #f4f6f6;
    }
  }
}

.mainBox {
  width: calc(100%);
  height: calc(100%);
}

::v-deep .el-menu {
  .el-submenu {
    .el-submenu_title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.navPic {
  width: 20px;
  height: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}

.el-badge {
  width: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

:deep .el-menu {
  .el-submenu {
    .el-menu {
      .el-menu-item-group {
        .el-menu-item .is-active {
          color: red !important;
        }
      }
    }
  }
}
</style>
