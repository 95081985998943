import hotelApi from "@/api/jiekou";
import { JSEncrypt } from "jsencrypt";
export function clone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = clone(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = clone(obj[key]);
      return newObj;
    }, {});
  }
}

// export function formatDate(date) {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   return `${year}-${month}-${day} ${hours}:${minutes}`;
// }

// 获取公钥及加密方法
export async function passwordEncryption(passwordUser) {
  const getPublicKeyRes = await hotelApi.getPublicKeyApi(); // 从后台获取公钥，这个保存一下，在这里用。
  const publicKey = getPublicKeyRes.data;
  console.log("后台公钥--------->:", publicKey);
  let encryptor = new JSEncrypt(); //新建JSEncrypt对象
  encryptor.setPublicKey(publicKey); //设置公钥
  let passwordEncryp = encryptor.encrypt(passwordUser); // 对密码进行加密
  return passwordEncryp;
}

/**
 * 判断两个值是否相等，使用 `Object.is` 判断。
 * @param {*} left
 * @param {*} right
 * @return {boolean}
 */
export function isEqual(left, right) {
  return Object.is(left, right);
}

/**
 * 判断第一个参数是否与后面的某个参数相等， 使用Object.is() 进行判断
 * @param {...*} args
 * @return {boolean}
 */
export function isOneOf(...args) {
  if (Array.isArray(args) && args.length > 0) {
    const target = args[0];
    return args.slice(1).some((arg) => Object.is(arg, target));
  }

  return false;
}

/**
 * 通过路径设置对象字段值， 如果路径不存在，会抛出异常
 * @param model
 * @param path
 * @param value
 * @return {*}
 */
function setValueByPath(model, path, value) {
  const paths = String(path).split(".");
  let tmp = model;
  let ctx;
  let key;
  for (key of paths) {
    if (key in tmp) {
      ctx = tmp;
      tmp = tmp[key];
    } else {
      throw new Error(`path ${key} not found in the giving object`);
    }
  }
  ctx[key] = value;

  return model;
}

//获取下拉?多选?复选数组text
export function getOptionsText(data, key, val) {
  return data.filter((item) => {
    return item[key] == val;
  });
}

/**
 * 格式化日期
 * @param date 时间戳（毫秒）
 * @param fmt 显示格式，默认为'YYYY-MM-DD hh:mm:ss'
 * @param emptyReturn 默认空值返回数据
 */
export function formatDate(date, fmt, emptyReturn) {
  if (!date && emptyReturn) {
    return emptyReturn;
  }
  date = date || new Date().getTime();
  fmt = fmt || "YYYY-MM-DD hh:mm:ss";
  date = new Date(Number(date));
  const o = {
    "M+": date.getMonth() + 1, // 月份
    "D+": date.getDate(), // 日
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    z: date.getMilliseconds(), // 毫秒
  };
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
    );
  }
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      );
    }
  });
  return fmt;
}

/**
 * 把字符串(yyyymmdd)转换成日期格式（yyyy-mm-dd）
 * @param {*} date
 * @returns
 */
export function replaceDate(date) {
  return date.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
}


/**
 * @param arr
 * @param targetValue
 * @param targetProperty
 * @param returnProperty
 * @returns {*|string}
 */
export function getValueByKeyFromArray(arr = [], targetValue, targetProperty = 'value', returnProperty = 'label') {
  if (!Array.isArray(arr)) return '';
  const target = arr.find((item) => `${item[targetProperty]}` === `${targetValue}`);
  return (target && target[returnProperty]) || '';
}
