const list = [
  "/loginMassage",
  "/404",
  "/registationAgreement",
  "/signInProcess",
  "/hotelCheckIn",
];
// export default list;
module.exports = {
  list,
};
