// 手机号验证
export function isvalidPhone(phone) {
  const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/;
  return reg.test(phone);
}
export function isSettledPhone(phone) {
  // 使用正则表达式来限制输入
  const regex = /^(13|14|15|16|17|18|19)\d{9}$/;
  return regex.test(phone);
}
//密码校验
export function isSettledPasswordValid(password) {
  const regex = /^[a-zA-Z0-9]{6,12}$/;
  return regex.test(password);
}
//电子邮件地址校验
export function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

/**
 * 手机号码和固化校验规则(-\d{1,4})指的是分机号码
 */
export const phoneRule = /^((0\d{2,3}-\d{7,8})(-\d{1,4})?|(1[3-9]\d{9}))$/;

/**
 * 身份证号码校验规则
 */
// export const idNumberRule = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
export const idNumberRule =
  /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dXx]$/;

/**
 * 电子邮件地址校验
 */
export const emailRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/**
 * 只能输入中文或英文
 */
export const nameRule = /^[a-zA-Z\u4e00-\u9fff\s*]+$/;

/**
 * 整数
 */
export const numberRule = /^\d{0,5}$/;

/**
 * 银行卡号
 */
export const banckRule = /^\d{0,20}$/;
/**
 * 营业执照编号
 */
export const businessLicenseRule =
  /^(?:(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/;
