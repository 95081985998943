/*
 * @Author: 冰熙 897227736@qq.com
 * @Date: 2024-10-29 10:18:54
 * @LastEditors: 冰熙 897227736@qq.com
 * @LastEditTime: 2024-12-02 10:56:01
 * @FilePath: \merchantmanage\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/css/theme/index.css";
//引入字体图标
import "./assets/fonts/iconfont.css";
// 引入全局样式
import "@/css/index.scss";
// import "@/helpers/px2rem";
Vue.config.productionTip = false;
// 创建事件总线
Vue.prototype.$bus = new Vue();
Vue.use(ElementUI);
//preventReClick 在需要防止重复提交的按钮加上自定义指令[v-preventReClick] 实例:<el-button v-preventReClick type="primary" @click="addroomType"> 新增房型 </el-button>
import * as directive from "@/helpers/directive";
for (const key in directive) {
  if (Object.prototype.hasOwnProperty.call(directive, key)) {
    const element = directive[key];
    Vue.directive(key, element);
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
