<!--
 * @Author: 冰熙 897227736@qq.com
 * @Date: 2024-11-22 20:29:47
 * @LastEditors: 冰熙 897227736@qq.com
 * @LastEditTime: 2024-11-25 10:36:10
 * @FilePath: \merchantmanage\src\components\AgreementDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    :width="w"
    center
    :before-close="closeDialog"
  >
    <div class="agreement-body" v-html="textHtml"></div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script lang="js">
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "协议"
        },
        w: {
            type: String,
            default: "40%"
        },
        textHtml: {
            type: String,
            default: "<div></div>"
        }
    },
    methods: {
        closeDialog() {
            this.$emit("update:dialogVisible",false)
        }
    }
}
</script>
<style lang="scss" scoped>
.agreement-body {
  height: 500px;
  overflow-y: auto;
}
</style>
