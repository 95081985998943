import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
import user from "./modules/user";
import getters from "./getters";
import hotel from "./modules/hotel";
//引入模块
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage, // 或者 localStorage
      paths: ["hotel", "user"],
    }),
  ],
  state: {
    allData: {
      hotelId: "", //酒店id
      roomTypeName: "", //房型名称
      roomLayout: 1, //空间布局    1单人间，2套房，3床位房
      //空间布局详情 注意三种不用的布局该处内容不同
      roomLayoutInfo: [
        {
          bedType: 1, //床类型  1单人床，2双人床，3大床，4沙发床，5上下铺，6胶囊床，7太空舱，8圆床，9水床，10榻榻米，11炕，12其他
          bedWidth: "", //床宽
          count: 1, //数量
        },
      ],
      hasWindow: 1, //是否有窗户 1全部有窗，2部分有窗，3全部房间无窗
      windowType: 1, //窗户类型 1窗户位于走廊或过道，2天窗，3封闭窗，4飘窗，5落地窗，6装饰性假窗，7窗户较小，8窗外有墙体或遮挡
      totalOccupancy: "", //总接待人数
      maxAdults: "", //最多入住成人
      maxChildren: 0, //最多入住儿童
      includesBreakfast: 1, //该房型是否包含早餐  0否 1是
      totalBreakfastServings: "", //总早餐份数
      areaSize: "", //使用面积
      floor: "", //楼层
      smokingAllowed: 1, //是否可吸烟 1全部客房可吸烟，2全部客房禁烟，3部分客房可吸烟
      roomCount: "", //房间数量
      roomDescription: "", //房型描述
      //房型图片
      roomImages: [],
      //热门设施等有关勾选项
      roomAmenitiesDetails: [
        {
          typeName: "热门设施",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "卫生间用品及设备",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "客房设施",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "客房设备及家具",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "室外景观",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "清洁服务",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "无障碍设施",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "儿童设施服务",
          includeStatus: 1,
          optionDetails: [],
        },
        {
          typeName: "厨房用品",
          includeStatus: 1,
          optionDetails: [],
        },
      ],
      //singleRoomTypeBasePrice: 199.99, //单件房型底价
      wholesaleRoomTypeBasePrice: "", //批发房型底价
      rejectionReason: "", //驳回原因
    },
    //图片管理模块控制页面显示状态
    massage: "lb",
    //图片管理每个房型列表下每条数据对应的roomId
    roomId: "",
    // 判断是否是登录状态
    token: "1",
    itemLabel: "",
  },
  getters: getters,
  mutations: {
    //存储新增房型数据
    addRoomData(state, payload) {
      state.allData = payload;
    },

    addRoomImages(state, payload) {
      state.allData.roomImages = payload;
    },

    //改变图片模块页面状态
    changePicStatus(state, payload) {
      state.massage = payload;
    },

    changeItemLabel(state, payload) {
      state.itemLabel = payload;
    },

    //改变roomId
    changeroomId(state, payload) {
      state.roomId = payload;
    },
    //改变token
    changeToken(state, payload) {
      state.token = payload;
    },
  },
  actions: {},
  // getters,
  modules: {
    user: user,
    hotel,
  },
});
