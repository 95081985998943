<template>
  <div>
    <!-- 登录页 -->
    <div class="loginAll">
      <!-- 登录部分 -->
      <div
        class="font-pages"
        :style="{
          'padding-top':
            selectName == 'seconed'
              ? screenWidth <= 1920
                ? '12vh'
                : '20vh'
              : '',
        }"
      >
        <!-- 图标+标题 -->
        <div class="logo">
          <img src="../../assets/image/navigation/img12.png" alt="" />
        </div>
        <div class="title">
          <span v-if="selectName == 'first'">登录到宿聚惠</span>
          <span v-if="selectName == 'seconed'">找回您的密码</span>
          <span v-if="selectName == 'third'">入驻四川宿聚惠</span>
          <span v-if="selectName == 'fourth'">修改您的密码</span>
        </div>
        <!-- 文字介绍 -->
        <div class="textMassage" v-if="selectName == 'first'">
          {{ text }}
        </div>
        <div
          class="textMassage"
          v-if="selectName == 'seconed' || selectName == 'fourth'"
        >
          我们将验证码以短信的形式发送到您的手机
        </div>
        <div class="setPwdClass" v-if="selectName == 'fourth'">修改密码</div>
        <!-- 选择登录方式 -->
        <div
          class="centerMian"
          v-if="selectName == 'first' || selectName == 'seconed'"
        >
          <el-tabs v-model="loginMethod" @tab-click="loginClick">
            <!-- 密码登录 -->
            <el-tab-pane
              label="密码登录"
              name="pwdLogin"
              v-if="selectName == 'first'"
            >
              <!-- 表单 -->
              <el-form
                ref="loginFormData"
                :model="loginForm"
                :rules="loignRules"
                label-width="80px"
              >
                <el-form-item prop="account">
                  <!-- oninput="value=value.replace(/[^0-9.]/g,'')" -->
                  <el-input
                    v-model="loginForm.account"
                    prefix-icon="el-icon-user"
                    placeholder="请输入账号"
                    maxlength="16"
                    minlength="3"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item prop="pwd">
                  <!-- oninput="value=value.replace(/[^0-9.]/g,'')" -->
                  <el-input
                    :type="flag ? 'text' : 'password'"
                    v-model="loginForm.pwd"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入密码"
                    clearable
                    maxlength="18"
                    minlength="6"
                    @keyup.enter.native="loginset('pwdLogin')"
                  >
                    <i slot="prefix" class="iconfont icon-clock finger"></i>
                    <i
                      slot="suffix"
                      class="iconfont finger el-input__icon"
                      :class="flag ? 'icon-openeyes' : 'icon-closeeyes'"
                      @click="flag = !flag"
                    ></i>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <div class="wordMassage" v-if="selectName == 'first'">
                    <div class="leftMassage">
                      <span>没有账号，</span>
                      <span @click="enterPush" class="span2">立即入驻</span>
                    </div>
                    <div class="rightMassage" @click="fotgetPwd">
                      忘记密码 ?
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    class="btn"
                    :loading="loginLoading"
                    @click="loginset('pwdLogin')"
                    >登录</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!-- 验证码登录 -->
            <el-tab-pane
              label="验证码登录"
              name="captchaLogin"
              v-if="selectName == 'first'"
            >
              <el-form
                ref="captchaFormData"
                :model="captchaForm"
                :rules="captchaRules"
                label-width="80px"
              >
                <el-form-item prop="num">
                  <el-input
                    v-model="captchaForm.num"
                    placeholder="请输入手机号"
                    prefix-icon="el-icon-user"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    maxlength="11"
                    clearable
                  ></el-input>
                </el-form-item>

                <el-form-item class="itemSpanClass" prop="captcha">
                  <el-input
                    type="text"
                    v-model="captchaForm.captcha"
                    placeholder="请输入验证码"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    prefix-icon="el-icon-lock"
                    clearable
                    maxlength="6"
                  >
                  </el-input>
                  <span
                    class="s1 finger"
                    v-if="captchaForm.num.length != 11"
                    @click="getCaptcha('captchaFormData')"
                    >获取验证码</span
                  >
                  <span
                    class="s2 finger"
                    v-if="captchaForm.num.length == 11"
                    @click="getCaptcha('captchaFormData')"
                    >{{ btnText }}</span
                  >
                </el-form-item>
                <el-form-item>
                  <div class="wordMassage" v-if="selectName == 'first'">
                    <div class="leftMassage">
                      <span>没有账号，</span>
                      <span @click="enterPush" class="span2">立即入驻</span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    class="btn"
                    @click="loginset('captchaLogin')"
                    >登录</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!-- 手机验证 -->
            <el-tab-pane
              label="手机验证"
              name="sjCaptchaLogin"
              v-if="selectName == 'seconed'"
            >
              <el-form
                ref="setPwdForm"
                :model="setPwdForm"
                :rules="setPwdRules"
                label-width="80px"
                key="seconed"
              >
                <el-form-item prop="num" class="inputClass">
                  <el-input
                    placeholder="请输入手机号"
                    maxlength="11"
                    v-model="setPwdForm.num"
                    class="input-with-select"
                    clearable
                    key="setPwdForm-num"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                  >
                    <template slot="prepend">
                      <el-select v-model="selectNum" placeholder="请选择">
                        <el-option
                          v-for="(item, i) in optionData"
                          :key="`q${i}`"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="captcha" class="itemSpanClass">
                  <el-input
                    type="text"
                    v-model="setPwdForm.captcha"
                    placeholder="请输入验证码"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    clearable
                    key="setPwdForm-captcha"
                  >
                  </el-input>
                  <span class="s1 finger" v-if="setPwdForm.num.length != 11"
                    >获取验证码</span
                  >
                  <span
                    class="s2 finger"
                    @click="getCaptcha('setPwdForm')"
                    v-if="setPwdForm.num.length == 11"
                    >{{ btnText }}</span
                  >
                </el-form-item>
                <el-form-item prop="pwd">
                  <el-input
                    placeholder="请输入密码"
                    v-model="setPwdForm.pwd"
                    class="input-with-select"
                    maxlength="18"
                    minlength="6"
                    clearable
                    show-password
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="enterPwd">
                  <el-input
                    type="text"
                    v-model="setPwdForm.enterPwd"
                    placeholder="确定新密码"
                    maxlength="18"
                    minlength="6"
                    clearable
                    show-password
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="setPwd()"
                    v-if="selectName == 'seconed'"
                    class="btn"
                  >
                    重置密码
                  </el-button>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="backLogin()"
                    v-if="selectName == 'seconed'"
                    class="btn"
                  >
                    返回登录
                  </el-button>
                </el-form-item>
              </el-form>
              <div class="centerMassage">
                <span v-for="(item, i) in textData" :key="`w${i}`">{{
                  item
                }}</span>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 立即入驻表单 -->
        <div class="centerMian" v-if="selectName == 'third'">
          <el-form
            ref="settledFormData"
            :model="settledForm"
            :rules="settledRules"
            label-width="80px"
          >
            <el-form-item prop="num">
              <el-input
                placeholder="请输入手机号"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                v-model="settledForm.num"
                class="input-with-select"
                clearable
                :maxlength="11"
              >
                <template slot="prepend"
                  >+86<i class="el-icon-arrow-down"></i
                ></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="captcha" class="itemSpanClass">
              <el-input
                type="text"
                v-model="settledForm.captcha"
                placeholder="请输入验证码"
                clearable
              >
              </el-input>
              <span
                class="s1 finger"
                v-if="settledForm.num.length != 11"
                @click="getCaptcha('settledFormData')"
                >获取验证码</span
              >
              <span
                class="s2 finger"
                @click="getCaptcha('settledFormData')"
                v-if="settledForm.num.length == 11"
                >{{ btnText }}</span
              >
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                placeholder="请输入密码"
                v-model="settledForm.password"
                class="input-with-select"
                maxlength="12"
                minlength="6"
                clearable
                show-password
              >
              </el-input>
            </el-form-item>

            <el-form-item prop="" class="checkedItem">
              <el-checkbox v-model="settledForm.isAgreementChecked">
                我已阅读并同意
              </el-checkbox>
              <a @click="goRegisAgreement(0)">《入驻协议》</a>
            </el-form-item>

            <el-form-item prop="" class="checkedItem">
              <a @click="goRegisAgreement(1)">《服务协议》</a>
              <a @click="goRegisAgreement(2)">《服务补充协议》</a>
              <a @click="goRegisAgreement(3)">《信息及隐私政策》</a>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="settle()"
                v-if="selectName == 'third'"
                class="btn"
              >
                立即入驻
              </el-button>
            </el-form-item>
            <el-form-item class="back-login">
              <div class="df jcsb">
                <span @click="backLogin" class="span2 finger">
                  已有账号，立即登录</span
                >

                <a
                  href="https://sujuhui-source.oss-cn-chengdu.aliyuncs.com/agreement.docx"
                  download="电子合同.docx"
                  >下载电子合同</a
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="login-record-number">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >蜀ICP备2024105962号</a
        >
      </div>
    </div>

    <!-- 选择管理的酒店 -->
    <el-dialog
      title="请选择店铺"
      :visible.sync="centerDialogVisible"
      width="25vw"
      center
    >
      <div class="demo-drawer-content">
        <div
          v-for="item in hotels"
          @click="slecetHote(item.HotelId)"
          :key="item.HotelId"
          :style="{
            color: hotelRadio == item.HotelId ? '#3270FF' : '',
            'box-shadow':
              hotelRadio == item.HotelId
                ? '0px 0px 10px 0px rgba(78, 89, 105, 0.25)'
                : '',
          }"
        >
          {{ item.HotelName }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeHotelSelcet">取 消</el-button>
        <el-button type="primary" @click="selectHotel">确 定</el-button>
      </div>
    </el-dialog>

    <agreement-dialog
      :dialogVisible.sync="agreementDialogVisible"
      :title="agreementDialogTitle"
      :textHtml="agreementHtml"
    ></agreement-dialog>
  </div>
</template>
<script>
import {
  isvalidPhone,
  isSettledPhone,
  isSettledPasswordValid,
} from "@/helpers/validate";
import { passwordEncryption } from "@/helpers/uitls";
import hotelApi from "@/api/jiekou";
import { debounce } from "lodash";
import AgreementDialog from "@/components/AgreementDialog.vue";
import { agreement } from "@/selectoption/agreement";
export default {
  components: { AgreementDialog },
  computed: {
    screenWidth() {
      return window.screen.width;
    },
  },
  data() {
    var validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else if (!isvalidPhone(value)) {
        //判断用户输入的值是否符合规则
        // callback(new Error("手机号输入有误"));
        callback(new Error("请输入格式正确的11位手机号码"));
      } else {
        callback();
      }
    };
    //密码校验
    var settledPasswordValid = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (value.length < 6 || value.length > 12) {
        callback(new Error("长度在 6 到 12 个字符"));
      } else if (!isSettledPasswordValid(value)) {
        callback(new Error("密码输入有误"));
      } else {
        callback();
      }
    };
    return {
      canGetCode: false, //控制发送验证码
      flag: false, //控制密码框显示
      isvalidPhone,
      isSettledPhone, //入驻账号规则
      isSettledPasswordValid, //密码规则
      selectName: "first", //控制页面转换
      loginMethod: "pwdLogin", //登录方式值
      text: "我们的服务管理系统致力于为加入平台的每个酒店商家提供稳定和可预测的收入流", //文字介绍
      loginForm: { account: "", pwd: "" }, //手机登录表单
      captchaForm: { num: "", captcha: "" }, //验证码登录表单
      settledForm: {
        num: "",
        captcha: "",
        password: "",
        isAgreementChecked: false,
      }, //入驻表单
      setPwdForm: { num: "", captcha: "", pwd: "", enterPwd: "" }, //修改密码表单
      selectNum: "", //选择手机号区号
      settledSelectNum: "", //入驻页面手机号区号
      reg: "", //手机号校验
      // validPhone:'',//手机号验证规则
      //  密码登录规则
      loignRules: {
        account: [
          { required: true, message: "请输入账号" },
          { min: 3, max: 16, message: "长度在 3 到 16 个字符" },
        ],
        pwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 18, message: "长度在 6 到 18个字符", trigger: "blur" },
        ],
      },
      // 手机号登录规则
      captchaRules: {
        num: [{ required: true, validator: validPhone, trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入验证码" },
          { min: 3, max: 6, message: "长度在 3 到 6个字符" },
        ],
      },
      // 手机号验证规则
      // sjCaptchaRules: {
      //   num: [{ required: true, validator: validPhone, trigger: "blur" }],
      //   captcha: [
      //     { required: true, message: "请输入验证码", trigger: "blur" },
      //     { min: 3, max: 8, message: "长度在 3 到 8个字符", trigger: "blur" },
      //   ],
      // },
      //修改密码验证
      setPwdRules: {
        num: [{ required: true, validator: validPhone, trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 3, max: 8, message: "长度在 3 到 8个字符", trigger: "blur" },
        ],
        pwd: [
          { required: true, message: "新密码不能为空" },
          { min: 6, max: 18, message: "长度在 6 到 18个字符" },
        ],
        enterPwd: [
          { required: true, message: "确认新密码不能为空", trigger: "blur" },
          { validator: this.validatePasswordMatch },
        ],
      },
      //入驻验证规则
      settledRules: {
        num: [{ required: true, validator: validPhone }],
        captcha: [
          { required: true, message: "请输入验证码" },
          { min: 3, max: 8, message: "长度在 3 到 8个字符" },
        ],
        password: [
          {
            required: true,
            validator: settledPasswordValid,
          },
        ],
      },
      // 手机号区号选择值
      optionData: [{ label: "+86", value: 1 }],
      // 修改密码提示性文字
      textData: [
        ".密码长度至少6位",
        ".含大小字母，数字，符号三种",
        ".不使用与其他网站相同的密码",
        "定期修改密码",
      ],
      btnText: "获取验证码",
      hotels: [], //当前登陆用户所拥有酒店
      centerDialogVisible: false,
      hotelRadio: "",

      agreementDialogVisible: false,
      agreementDialogTitle: "",
      agreementHtml: "",
      loginLoading: false,
    };
  },
  methods: {
    // 登录方式改变事件
    loginClick(tab, event) {},
    //登录验证
    loginset: debounce(function (type) {
      if (type === "pwdLogin") {
        this.loginLoading = true;
        //登录逻辑
        this.$refs.loginFormData.validate(async (valid) => {
          if (valid) {
            try {
              const encryptedPassword = await passwordEncryption(
                this.loginForm.pwd
              );
              // 调用登陆接口
              const loginRes = await hotelApi.loginApi({
                phoneNumber: this.loginForm.account, // 将 account 映射为 phoneNumber
                password: encryptedPassword, // 将 pwd 映射为 password
              });
              let { status, data, errorMsg } = loginRes;
              if (status === 200 && data) {
                // this.$message.success("登陆成功");
                this.onStorage(data);
              } else {
                this.$message.error(errorMsg);
                this.loginLoading = false;
              }
            } catch (error) {
              console.log(
                "🚀 ~ this.$refs.loginFormData.validate ~ error:",
                error
              );
            }
          } else {
            return false;
          }
        });
      }
      if (type == "captchaLogin") {
        this.$refs.captchaFormData.validate((valid) => {
          if (valid) {
            hotelApi
              .verifyCodeLoginApi({
                phoneNumber: this.captchaForm.num,
                code: this.captchaForm.captcha,
              })
              .then((res) => {
                if (res.status === 200 && res.data) {
                  // this.$message.success("登陆成功");
                  this.onStorage(res.data);
                } else {
                  this.$message.error(res.errorMsg);
                }
              });
          } else {
            return false;
          }
        });
      }
    }, 1500),
    //忘记密码
    fotgetPwd() {
      this.selectName = "seconed";
      this.loginMethod = "sjCaptchaLogin";
    },
    //确认新密码校验：
    validatePasswordMatch(rule, value, callback) {
      if (value !== this.setPwdForm.pwd) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    },
    // 找回密码
    setPwd() {
      this.$refs.setPwdForm.validate(async (valid) => {
        if (valid) {
          try {
            const encryptedSetPwd = await passwordEncryption(
              this.setPwdForm.pwd
            ); // 确保加密完成
            const setPwdRes = await hotelApi.changePasswordApi({
              phoneNumber: this.setPwdForm.num,
              password: encryptedSetPwd,
              code: this.setPwdForm.captcha,
            });
            let { status, data, errorMsg } = setPwdRes;
            if (status === 200 && data) {
              this.$message.success(data);
              setTimeout(() => {
                this.setPwdForm = {
                  num: "",
                  captcha: "",
                  pwd: "",
                  enterPwd: "",
                };
                this.backLogin();
              }, 1000);
            } else {
              this.$message.error(errorMsg);
            }
          } catch (error) {
            console.log("🚀 ~ this.$refs.setPwdForm.validate ~ error:", error);
          }
        }
      });
    },
    // 返回登录
    backLogin() {
      this.selectName = "first";
      this.loginMethod = "pwdLogin";
    },
    // 立即入驻
    settle() {
      this.$refs.settledFormData.validate(async (valid) => {
        if (this.settledForm.isAgreementChecked === true) {
          if (valid) {
            let settledForm = JSON.parse(JSON.stringify(this.settledForm));
            let ecodePassWord = await passwordEncryption(settledForm.password);
            let obj = {
              phoneNumber: settledForm.num,
              password: ecodePassWord,
              code: settledForm.captcha,
            };
            hotelApi.getCaptchaApi(obj).then(async (res) => {
              if (res.status == 200) {
                //注册成功执行账号密码登陆方法直接登陆
                const loginRes = await hotelApi.loginApi({
                  phoneNumber: settledForm.num,
                  password: ecodePassWord,
                });
                let { status, data, errorMsg } = loginRes;
                if (status === 200 && data) {
                  this.onStorage(data);
                } else {
                  this.$message.error(errorMsg);
                }
              }
            });
          }
        } else {
          this.$message("请勾选商家入驻协议");
        }
      });
    },
    //跳协议页面
    goRegisAgreement(type) {
      // this.$router.push({ name: "registationAgreement" });
      this.agreementDialogVisible = true;
      switch (type) {
        case 0:
          this.agreementDialogTitle = "酒店商家入驻说明";
          break;
        case 1:
          this.agreementDialogTitle = "宿聚惠E-Booking服务协议";
          break;
        case 2:
          this.agreementDialogTitle = "预订服务补充协议";
          break;
        case 3:
          this.agreementDialogTitle = "商家端信息及隐私政策";
          break;
        default:
          break;
      }
      this.agreementHtml = agreement[type];
    },
    //立即入驻文字
    enterPush() {
      this.selectName = "third";
    },
    // 保存修改
    // saveChange() {
    //   this.selectName = "first";
    //   this.loginMethod = "pwdLogin";
    // },
    //获取验证码按钮
    getCaptcha(v) {
      console.log("🚀 ~ getCaptcha ~ v:", v);
      if (v == "captchaFormData") {
        this.$refs.captchaFormData.validateField("num", (errorMessage) => {
          if (!errorMessage) {
            if (this.canGetCode) return;
            this.canGetCode = true;
            this.doLoop(60);
            this.getPhoneCode({
              phone: this.captchaForm.num,
              codeType: "login",
            });
          } else {
            return false;
          }
        });
      }
      if (v == "setPwdForm") {
        this.$refs.setPwdForm.validateField("num", (errorMessage) => {
          if (!errorMessage) {
            this.$message("已发送验证码，请查收");
            setTimeout(() => {
              this.doLoop(60);
              this.getPhoneCode({
                phone: this.setPwdForm.num,
                codeType: "reset",
              });
            }, 500);
          } else {
            return false;
          }
        });
      }

      if (v == "settledFormData") {
        this.$refs.settledFormData.validateField("num", (errorMessage) => {
          if (!errorMessage) {
            if (this.canGetCode) return;
            this.canGetCode = true;
            this.doLoop(60);
            this.getPhoneCode({
              phone: this.settledForm.num,
              codeType: "register",
            });
          } else {
            return false;
          }
        });
      }
    },
    //手机验证码倒计时
    doLoop(seconds) {
      seconds = seconds ? seconds : 60;
      this.btnText = seconds + "s后获取";
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + "s后获取";
          --seconds;
        } else {
          this.canGetCode = false;
          this.btnText = "重新获取验证码";
          // this.btnText = "获取验证码";
          clearInterval(countdown);
        }
      }, 1000);
    },
    // 获取手机验证码
    async getPhoneCode(val) {
      let res = await hotelApi.getPhoneCodeApi(val);
      if (res.status == 200) {
        this.$message("已发送验证码，请查收");
      }
    },
    // 获取用户信息
    async getHotelBusinessInfo() {
      let res = await hotelApi.getHotelBusinessInfoApi();
      // console.log(res);
      if (res.status == 200) {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.$store.commit("user/SET_USERINFO", res.data);
      }
      return res;
    },
    // 获取用户路由
    async getHotelBusinessRoute() {
      let res = await hotelApi.getHotelBusinessRouteApi();
      if (res.status == 200) {
        localStorage.setItem("powerList", JSON.stringify(res.data));
        this.$store.commit("user/SET_POWERLIST", res.data);
      }
    },
    //点击酒店选择取消按钮
    closeHotelSelcet() {
      this.centerDialogVisible = false;
    },
    // 一些存储功能
    async onStorage(val) {
      localStorage.setItem("setToken", val); // 将 token 存储到 本地
      this.$store.commit("user/SET_TOKEN", val); // 存储 token 到 Vuex
      this.getHotelBusinessRoute();
      let hotelBusinessInfo = await this.getHotelBusinessInfo();
      if (hotelBusinessInfo?.data?.isMainAccount) {
        //获取用户所管理的酒店 如果没有管理酒店则跳转入驻流程页面
        hotelApi.getHotelIds().then((res) => {
          if (res.status == 200) {
            this.loginLoading = false;
            if (res.data && res.data.length) {
              this.hotels = res.data;
              //只有一个所属酒店直接进入系统
              if (res.data.length == 1) {
                this.slecetHote(res.data[0].HotelId);
                this.selectHotel();
              } else {
                this.centerDialogVisible = true;
              }
            } else {
              this.$router.push({ name: "signInProcess" });
            }
          }
        });
      }
    },
    //选择店铺
    slecetHote(hotelId) {
      this.hotelRadio = hotelId;
    },
    // 选择管理的酒店
    async selectHotel() {
      if (this.hotelRadio) {
        this.$store.dispatch("hotel/setHoteId", this.hotelRadio);
        let res = await hotelApi.InquireBasicInformationHotelQuery(
          this.hotelRadio
        );
        // console.log("-----------+++++++++--------", res);
        if (res.status == 200) {
          this.$store.dispatch("hotel/setHotelDet", res.data);
          setTimeout(() => {
            this.$router.push({ name: "hotelInformation" });
          }, 500);
        }
      } else {
        this.$message.warning("请选择您要管理的酒店");
      }
    },
  },
  mounted() {
    if (process.env.VUE_APP_NODE_KEY == "development") {
      this.loginForm.account = "19999999999";
      this.loginForm.pwd = "123456789";
    }
    console.log(
      `当前环境--->%c${process.env.VUE_APP_NODE_KEY}`,
      "color: red; font-size: 18px;"
    );
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

.loginAll {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-image: url("~@/assets/image/login/image01.png");
  background-position: center;
  background-size: 100% 100%;
  position: relative;

  .login-record-number {
    position: absolute;
    width: 100%;
    bottom: 2px;
    text-align: center;

    a {
      font-size: 14px;
    }
  }
}

.font-pages {
  position: absolute;
  width: 37.5%;
  height: 100%;
  right: 0;
  padding: 24vh 8.28vw;

  .title {
    height: 44px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 40px;
    color: #1d2129;
    line-height: 44px;
    text-align: left;
    margin: 48px 0 16px 0;
  }

  .textMassage {
    width: 396px;
    height: auto;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #4e5969;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 56px;
  }

  .wordMassage {
    width: 400px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #86909c;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: #333;
      opacity: 0.44;
    }

    .span2 {
      color: #3270ff;
      opacity: 1;
      cursor: pointer;
    }

    .rightMassage {
      color: #3270ff;
      cursor: pointer;
    }
  }
}

.butClass {
  margin-top: 48px;
  width: 400px;
  height: 44px;

  button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: #3270ff;
    color: #fff;
    border-radius: 4px;
  }

  .backLogin {
    margin-top: 24px;
    background-color: #f2f3f5;
    color: #4e5969;
  }
}

.setPwdClass {
  width: 80px;
  height: 28px;
  font-weight: 500;
  font-size: 20px;
  color: #1d2129;
  line-height: 28px;
  text-align: left;
  margin-bottom: 24px;
}

.centerMassage {
  margin-top: 24px;

  span {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #86909c;
  }
}

::v-deep .checkedItem {
  text-align: left;
  display: block;
  /* 确保它占据整行 */
  margin-bottom: 0px !important;
  height: 40px !important;
  width: 100%;
  word-break: break-all;
  a {
    color: #3270ff;
    cursor: pointer;
  }
}

.back-login {
  .span2 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #3270ff;
  }
}

:deep .el-tabs {
  width: 400px;

  .el-tabs__header {
    .el-tabs__nav-wrap::after {
      display: none;
    }

    .is-active {
      color: #333 !important;
    }

    .el-tabs__active-bar {
      background-color: #3270ff;
    }
  }

  .el-tabs__item {
    font-size: 20px;
  }
}

:deep .el-form {
  .el-form-item {
    width: 400px;
    margin-bottom: 24px;

    .el-form-item__content {
      width: 400px;
      height: 46px;
      margin-left: 0 !important;

      .el-input {
        width: 100%;
        height: 100%;

        .el-input__inner {
          height: 100%;
        }
      }
    }
  }

  .itemSpanClass {
    position: relative;

    .s1 {
      line-height: 46px;
      position: absolute;
      top: 0px;
      right: 10px;
      color: #4e5069;

      &:hover {
        cursor: pointer;
      }
    }

    .s2 {
      line-height: 46px;
      position: absolute;
      top: 0px;
      right: 10px;
      color: #3270ff;
    }
  }
}

:deep .inputClass {
  .el-form-item__content {
    .input-with-select {
      .el-input-group__prepend {
        background-color: #fff;

        .el-select {
          width: 100px;
        }
      }
    }
  }
}

.btn {
  width: 100%;
  height: 46px;
  font-size: 16px;
}

::v-deep .el-form .itemSpanClass .el-form-item__content {
  .el-input {
    position: relative;

    //验证码框的清除按钮位置
    .el-input__suffix {
      position: absolute;
      right: 80px;
      top: 2px;
    }
  }
}

//输入框里的图标位置
::v-deep .el-form .el-form-item .el-form-item__content {
  .el-input {
    position: relative;

    .el-input__prefix {
      position: absolute;
      top: 2px;
    }

    .el-input__suffix {
      position: absolute;
      top: 2px;
    }
  }
}

.el-radio {
  width: 320px;
  margin: 5px;
}

::v-deep .el-dialog__header {
  background-image: url("../../assets/image/login/el-drawer-header.png") !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 104px;

  // padding-top: 0;
  .el-dialog__title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #3270ff;
    // line-height: 104px;
  }
}

.demo-drawer-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.2vw;
  height: 24vh;
  overflow-y: auto;

  div {
    width: 23.5vw;
    height: 6.8vh;
    line-height: 6.8vh;
    background: #f9f9fa;
    border-radius: 4px 4px 4px 4px;
    margin: 0.5vh 0.5vw;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #4e5969;
    padding-left: 1.2vw;

    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(78, 89, 105, 0.25);
      cursor: pointer;
    }
  }
}
</style>
