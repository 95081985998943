<!--
 * @Author: 冰熙 897227736@qq.com
 * @Date: 2024-10-29 10:18:54
 * @LastEditors: 冰熙 897227736@qq.com
 * @LastEditTime: 2024-12-06 16:11:49
 * @FilePath: \merchantmanage\src\layout\Layout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <el-container>
      <el-header>
        <layout-header></layout-header>
      </el-header>
      <el-container class="container">
        <!-- 左侧导航栏 -->
        <el-aside :style="{ width: collapse ? '64px' : '' }">
          <el-menu
            :default-active="defaultAtive"
            class="el-menu-vertical-demo"
            :unique-opened="true"
            :collapse="collapse"
          >
            <el-submenu
              v-for="(item, index) in routerList"
              :index="item.path"
              :key="`${index + 1}`"
            >
              <template slot="title">
                <img
                  style="width: 16px; height: 16px"
                  v-if="item.img"
                  :src="defaultAtive.includes(item.path) ? item.imgS : item.img"
                  alt=""
                />
                <i v-if="item.icon" :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>

              <el-menu-item-group>
                <el-menu-item
                  v-for="(childrenItem, childrenIndex) in item.children"
                  :index="childrenItem.path"
                  :key="`${index + 1}-${childrenIndex + 1}`"
                  @click.native.stop="
                    menuItemClick(
                      childrenItem.path,
                      `${index + 1}-${childrenIndex + 1}`
                    )
                  "
                  >{{ childrenItem.title }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <!-- 中间主要内容 -->
        <el-main>
          <div class="mainBox">
            <div v-if="isShowBreadcrumb" class="pageViewBox">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item
                  :key="item.name"
                  v-for="item in breadcrumbData"
                  :to="{ path: item.path }"
                  >{{ item.meta.name }}</el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
const powerList = require("@/router/power");
const LayoutHeader = () => import("./components/Layoutheader.vue");
export default {
  name: "App",
  components: { LayoutHeader },
  data() {
    return {
      asideList: [
        {
          title: "工作中心",
          path: "/workCenter",
          img: require("../assets/image/navigation/nav-img1.png"),
          imgS: require("../assets/image/navigation/nav-img1-s.png"),
          pic: "",
          children: [
            {
              title: "工作中心",
              path: "/workCenter/workcenter",
            },
          ],
        },
        // {
        //   title: "任务中心",
        //   path: "/taskCenter",
        //   img: require("../assets/image/navigation/nav-img2.png"),
        //   pic: "",
        //   children: [],
        // },
        // {
        //   title: "数据中心",
        //   img: require("../assets/image/navigation/nav-img3.png"),
        //   path: "/dataCenter",
        //   pic: "",
        //   children: [],
        // },
        // {
        //   title: "经营指南",
        //   img: require("../assets/image/navigation/nav-img4.png"),
        //   path: "/tradeTeach",
        //   pic: "",
        //   children: [],
        // },
        {
          title: "信息管理",
          img: require("../assets/image/navigation/nav-img5.png"),
          imgS: require("../assets/image/navigation/nav-img5-s.png"),
          path: "/inforManage",
          pic: "",
          children: [
            {
              title: "酒店信息",
              path: "/inforManage/hotelInformation",
            },
            {
              title: "酒店亮点",
              path: "/inforManage/hotelhighlights",
            },
          ],
        },
        {
          title: "订单管理",
          img: require("../assets/image/navigation/nav-img6.png"),
          imgS: require("../assets/image/navigation/nav-img6-s.png"),
          path: "/orderManage",
          pic: "",
          children: [
            {
              title: "接单大厅",
              path: "/orderManage/receptionHall",
            },
            // {
            //   title: "自动接单",
            //   path: "/orderManage/automaticOrderAcceptance",
            // },
            {
              title: "我的订单",
              path: "/orderManage/myOrderManagement",
            },
          ],
        },
        // {
        //   title: "评价管理",
        //   img: require("../assets/image/navigation/nav-img7.png"),
        //   path: "/commentManage",
        //   pic: "",
        //   children: [],
        // },
        {
          title: "房态房量",
          img: require("../assets/image/navigation/nav-img8.png"),
          imgS: require("../assets/image/navigation/nav-img8-s.png"),
          path: "/housePrice",
          pic: "",
          children: [
            {
              title: "房态房量",
              path: "/housePrice/roomStatusAndQuantity",
            },
            {
              title: "房价管理",
              path: "/housePrice/housePriceList",
            },
            {
              title: "节假日",
              path: "/housePrice/holidayAndVacations",
            },
          ],
        },
        {
          title: "系统管理",
          img: require("../assets/image/navigation/nav-img9.png"),
          imgS: require("../assets/image/navigation/nav-img9-s.png"),
          path: "/systemManage",
          pic: "",
          children: [
            // {
            //   title: "账号管理",
            //   path: "/systemManage/accountNumber",
            // },
            {
              title: "店铺管理",
              path: "/systemManage/storeManagement",
            },
            // {
            //   title: "角色管理",
            //   path: "/systemManage/roleManagement",
            // },
            // {
            //   title: "成员管理",
            //   path: "/systemManage/menberManagement",
            // },
            // {
            //   title: "修改密码",
            //   path: "/systemManage/changePassword",
            // },
          ],
        },
      ],
      defaultAtive: "",
      breadcrumbData: [],
      isShowBreadcrumb: false,
    };
  },
  computed: {
    routerList() {
      return this.asideList.filter((item) =>
        powerList.list.includes(item.path)
      );
    },
    user() {
      return JSON.parse(sessionStorage.getItem("vuex")).user.userInfo;
    },
    collapse() {
      return false;
    },
  },
  watch: {
    // 监听路由对象$route的变化
    $route: {
      handler: function (to, from) {
        // 路由发生变化时执行的代码
        // console.log("Route changed from", from, "to", to);
        this.breadcrumbData = [];
        if (to?.meta?.isShowBreadcrumb) {
          this.isShowBreadcrumb = true;
          this.breadcrumbData.push(from);
          this.breadcrumbData.push(to);
          // console.log(this.breadcrumbData);
        } else {
          this.defaultAtive = to.path;
          this.isShowBreadcrumb = false;
        }
      },
      // 设置为深度监听
      deep: true,
    },
  },
  methods: {
    menuItemClick(v, defaultAtive) {
      this.$router.push(v);
    },
  },
  created() {
    // console.log(this.$route);
    this.defaultAtive = this.$route.path;
  },
  mounted() {
    console.log(this.user);
  },
};
</script>
<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f5f6f8;
}
.el-header {
  padding: 0;
  width: 100vw;
}
.el-aside {
  height: 100%;
  background: #ffffff;
  width: 220px;
}

// .el-main {
//   padding: 0 20px 0 20px;
//   width: calc(1920px - 220px);
// }

:deep .el-container {
  width: 100vw;
  height: 100vh;
  .container {
    height: calc(100% - 76px);
    margin-top: 16px;
    overflow: hidden;
    /* 为所有的滚动条设置样式 */
    ::-webkit-scrollbar {
      width: 8px;
      /* 设置滚动条的宽度 */
    }
    /* 滚动条轨道 */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      /* 设置滚动条轨道的背景颜色 */
    }
    /* 滚动条的滑块 */
    ::-webkit-scrollbar-thumb {
      background: #888;
      /* 设置滚动条滑块的背景颜色 */
    }
    /* 当鼠标滑过滑块 */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
      /* 设置滚动条滑块的鼠标悬停背景颜色 */
    }
    .el-main {
      background-color: #f4f6f6;
      padding: 0 20px 0 20px;
      width: calc(1920px - 220px);
    }
  }
}

.mainBox {
  width: calc(100%);
  height: calc(100%);
}

::v-deep .el-menu {
  border-right: 0px;

  .el-submenu {
    .el-submenu_title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.navPic {
  width: 20px;
  height: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}

.el-badge {
  width: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

:deep .el-menu {
  .el-menu-item,
  .el-submenu__title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #4e5969;
    img {
      margin-right: 16px;
    }
  }

  .el-submenu.is-active.is-opened {
    .el-submenu__title {
      color: #165dff;
    }
  }
  .el-menu-item:hover {
    color: #165dff !important;
    background: #f2f3ff !important;
  }

  .el-menu-item.is-active {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #165dff;
    background: #f2f3ff;
    border-radius: 2px 2px 2px 2px;
  }
}

/* 定义进入和离开的过渡状态 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
