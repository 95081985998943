import axios from "axios"; //引入
import { Message, MessageBox } from "element-ui";
import jsonBig from "json-bigint";
import router from "@/router";

export let baseURL =
  process.env.VUE_APP_NODE_KEY == "test"
    ? "http://47.113.222.208:19000/"
    : process.env.VUE_APP_NODE_KEY == "production"
    ? "https://service.sujuhui.com/"
    : "http://47.109.32.193:19000/";
let config = {
  baseURL: baseURL,
  withCredentials: true, // 跨域
  timeout: 30000, //设置最大请求时间
  contentType: "application/json",
  transformResponse: [
    function (data) {
      //transformResponse这个配置项可以拦截接口返回的内容进行处理
      try {
        // 如果大数字类型转换成功则返回转换的数据结果
        return jsonBig.parse(data);
      } catch (err) {
        // 如果转换失败，代表没有长数字可转，正常解析并返回
        return JSON.parse(data);
      }
    },
  ],
};
const _axios = axios.create(config);
/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("setToken");
    // let token='eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJNQUlOX0JVU0lORVNTIl0sInN1YiI6IjE4MjY5NTMzODE2NzUxNjc3NDUiLCJpYXQiOjE3MjQ2Mzg0MzksImV4cCI6MTczMzI3ODQzOX0.UbC0H678lRLkapmrJ-92znzIslkEWGc_w4ewMppGPVe6fn7P1JzTVYxsi6ldWBA1Cd4ZZiPfSgbHFNjGB2w7JQ'
    if (token) {
      config.headers = {
        Authorization: token,
      };
    }
    return config;
  },
  (err) => Promise.reject(err)
);

/* 响应拦截器即异常处理 */
_axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.data.status === 200) {
      return Promise.resolve(response);
    } else {
      Message(response.data.errorMsg);
      return Promise.reject(response);
    }
  },
  (error) => {
    // 状态码判断处理
    if (error.response.status == 500) {
      Message("请求服务器错误");
    } else if (error.response.status == 401) {
      Message("token过期，请重新登录");
      // setTimeout(()=>{
      //   window.location.href= 'https://sys.ycyd777.com/login'
      // },1500)
      //this.$router.path("/login");
      router.push("/loginMassage");
    } else {
      Message(`'连接错误'${error.response.status}`);
    }
    return Promise.reject(error);
  }
);

//封装post,get方法
const myaxios = {
  get(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        params,
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: "GET",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  post(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {});
    });
  },
};

export default myaxios;
