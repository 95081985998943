/*
 * @Author: 冰熙 897227736@qq.com
 * @Date: 2024-10-29 10:18:54
 * @LastEditors: 冰熙 897227736@qq.com
 * @LastEditTime: 2024-12-02 10:52:14
 * @FilePath: \merchantmanage\src\helpers\directive.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Message } from "element-ui";
export const preventReClick = {
  inserted: function (el, binding) {
    el.addEventListener("click", () => {
      // console.log(el);
      if (!el.disabled) {
        el.disabled = true;
        el.classList.add("is-disabled");
        setTimeout(() => {
          el.disabled = false;
          el.classList.remove("is-disabled");
        }, binding.value || 2000);
      }
    });
  },
};

export const decimalDirective = {
  bind(el, binding, vnode) {
    const inputComponent = el.__vue__ || vnode.child || vnode.child.$parent;
    if (inputComponent && inputComponent.$options.componentName === "ElInput") {
      inputComponent.$refs.input.addEventListener("input", function (event) {
        let value = event.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
        if (!regex.test(value)) {
          // 提示
          Message.error("请输入有效的非负数，最多两位小数");
          // 强制修改
          const parts = value.replace(/[^0-9.]/g, "").split(".");
          value = `${parts[0]}.${parts[1] ? parts[1].slice(0, 2) : ""}`;
        }
        event.target.value = value;
        vnode.child.$emit("input", value);
      });
    }
  },
};

export const noHtml = {
  inserted: function (el) {
    el.addEventListener("input", function (e) {
      let value = e.target.value;
      // 使用正则表达式替换HTML标签为空字符串
      value = value.replace(/<[^>]+>/g, "");
      e.target.value = value;
    });
  },
};
