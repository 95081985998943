import myaxios from "@/http/myaxios";

// 请求基础地址
const hotelApi = {
  //获取公钥
  getPublicKeyApi() {
    return myaxios.get("/auth/rsa/getPublicKey");
  },

  //立即入驻
  getCaptchaApi(v) {
    return myaxios.post("/auth/business/register", v);
  },

  //获取验证码
  getPhoneCodeApi(v) {
    return myaxios.get("/auth/business/getPhoneCode/", v);
  },
  //验证码登录
  verifyCodeLoginApi(v) {
    return myaxios.post("/auth/business/verifyCodeLogin", v);
  },
  //酒店商家修改密码
  changePasswordApi(v) {
    return myaxios.post("/auth/business/changePassword", v);
  },
  //获取酒店商家用户基本信息
  getHotelBusinessInfoApi() {
    return myaxios.get("/auth/business/online/getHotelBusinessInfo");
  },
  //获取酒店商家用户路由权限
  getHotelBusinessRouteApi() {
    return myaxios.get("/auth/business/online/getHotelBusinessRoute");
  },
  //检测的商家用户是否已经注册
  isPhoneRegisterApi(v) {
    return myaxios.post(`/auth/business/checkPhone/${v}`, v);
  },
  /**
   * 禁用或启用状态
   */
  changeHotelStatusApi(hotelId, status) {
    return myaxios.post(
      `/hotel/storeManagement/changeHotelIsBan/${hotelId}/${status}`
    );
  },
  //登陆
  loginApi(v) {
    return myaxios.post("/auth/business/login", v);
  },

  // 上传图片
  pushPic(v) {
    return myaxios.post("/common/minio/uploadImgGetName", v);
  },
  // 编辑详情页面-回显图片
  getNameAndUrl(v) {
    return myaxios.post("/common/minio/uploadImgGetNameAndUrl", v);
  },
  //酒店新增
  addStoreApi(v) {
    return myaxios.post("/hotel/Settle/add", v);
  },

  //获取入驻详细信息
  getHotelInfoApi(hotelId) {
    return myaxios.get(`/hotel/Settle/detailsInfo/${hotelId}`);
  },
  //获取入驻记录
  getHotelRecordApi(hotelId) {
    return myaxios.get(`/hotel/Settle/auditRecord/${hotelId}`);
  },

  //获取酒店分页信息
  getHotelPageInfoApi(params) {
    return myaxios.get("/hotel/storeManagement/getHotelsInfoPage", params);
  },

  //删除酒店
  deleteHotelApi(hotelId) {
    return myaxios.post(`/hotel/storeManagement/deleteHotel/${hotelId}`);
  },
  //获取图片
  picQuery(v) {
    return myaxios.get(`/common/minio/getImgUrl/${v}`);
  },
  // 检测新入住商家是否补充完整
  detectSupplementQuery(v) {
    return myaxios.get(`/hotel/information/checkFistIsComplete/${v}`);
  },
  // 酒店基本信息初次填写
  hotelFirstFillBody(v) {
    return myaxios.post("/hotel/information/setHotelInfo", v);
  },
  // 查询指定酒店基本信息
  InquireBasicInformationHotelQuery(v) {
    return myaxios.get(`/hotel/information/getHotelInfo/${v}`);
  },
  // 更新指定酒店基本信息
  updateBasicInformationHotelBody(v) {
    return myaxios.post("/hotel/information/updateHotelInfo", v);
  },
  // 查询指定酒店政策内容
  InquirePolicyHotelQuery(v) {
    return myaxios.get(`/hotel/information/getHotelPolicies/${v}`);
  },
  // 更新指定酒店政策内容
  updatePolicyHotelBody(v) {
    return myaxios.post("/hotel/information/updateHotelPolicies", v);
  },
  // 查询指定酒店设施内容
  inquireFacilityHotelQuery(v) {
    return myaxios.get(`/hotel/information/getHotelAmenities/${v}`);
  },
  // 更新指定酒店设施内容
  updateFacilityHotelBody(v) {
    return myaxios.post("/hotel/information/updateHotelAmenities", v);
  },
  // 查询标签列表
  listOfLabelsQuery() {
    return myaxios.get("/hotel/information/getTagsList");
  },
  // 查询标签联系搜索
  tagPredictionSearchQuery(v) {
    return myaxios.get("/hotel/information/searchTags", v);
  },
  // 查询指定酒店亮点信息
  findOutHotelHighlightsQuery(v) {
    return myaxios.get(`/hotel/information/getHotelHighlights/${v}`);
  },
  // 保存or更新酒店亮点
  saveOrUpdateHotelHighlightsBody(v) {
    return myaxios.post("/hotel/information/saveOrUpdateHotelHighlights", v);
  },
  // 获取酒店图文信息
  getHotelGraphicsQuery(v) {
    return myaxios.get(`/hotel/information/getHotelHighlightsImageText/${v}`);
  },
  // 更新酒店图文信息

  updateHotelGraphicsBody(v) {
    return myaxios.post("/hotel/information/updateHotelHighlightsImageText", v);
  },
  //获取所有房型列表
  getListOfAllRoomTypesQuery(v) {
    return myaxios.get(`/hotel/room/information/getRoomTypeList/${v}`);
  },
  //保存或者更新房型信息
  saveOrUpdateRoomTypeInformationBody(v) {
    return myaxios.post("/hotel/room/information/saveOrUpdateRoomType", v);
  },
  //获取指定Id待审核，驳回
  getObtainIdReviewAndRejectQuery(v) {
    return myaxios.get(
      `/hotel/room/information/getReviewListRoomyInfoById/${v}`
    );
  },
  // 获取指定id上架，下架
  getObtainIDListedtakenShelfQuery(v) {
    return myaxios.get(`/hotel/room/information/getRoomInfo/${v}`);
  },
  //删除审核房型
  deleteApprovedRoomTypeBody(v) {
    return myaxios.post(`/hotel/room/information/deleteRoomReviewById/${v}`);
  },
  // 删除上下架房型
  deleteUpperLowerRoomTypesBody(v) {
    return myaxios.post(`/hotel/room/information/deleteRoomInfoById/${v}`);
  },
  // 上下架房型
  upperLowerRackRoomTypesBody(v) {
    return myaxios.post("/hotel/room/information/upOrDownRoomInfo", v);
  },

  //上传不同类型的图片信息
  uploadDifferentTypesImageBody(v) {
    return myaxios.post("/hotel/information/image/uploadImageOfModule", v);
  },
  //查询 外观/大厅/餐饮/公共设施/房型 五类图片列表
  queryListFiveTypesQuery(v) {
    return myaxios.get(`/hotel/information/image/queryImageList/${v}`);
  },
  //全部类型列表
  listOfAllTypesQuery(v) {
    return myaxios.get(`/hotel/information/image/queryAllImageList/${v}`);
  },
  //设置酒店首图
  setTheHotelHeaderBody(v) {
    return myaxios.post(`/hotel/information/image/setHotelFirstImage/${v}`);
  },
  //房型选择首图
  selectFirstImageRoomTypeBody(v) {
    return myaxios.post(`/hotel/information/image/setRoomFirstImage/${v}`);
  },
  //批量下架
  bulkDelistingBody(v) {
    return myaxios.post("/hotel/information/image/batchRemoveImages", v);
  },
  //批量删除
  deleteBulkBody(v) {
    return myaxios.post("/hotel/information/image/batchDeleteImages", v);
  },
  //批量修改分类
  modifyClassificationsBatchesBody(v) {
    return myaxios.post("/hotel/information/image/batchModifyCategory", v);
  },
  //获取指定类图片列表 （不包括房型图片）
  obtainSpecifiedImageCategoryBody(v1, v2) {
    return myaxios.get(
      `/hotel/information/image/getSpecifyTypeImageList/${v1}/${v2}`
    );
  },
  //获得指定房型图片列表
  specificRoomTypeQuery(v) {
    return myaxios.get(`/hotel/information/image/getSpecifyRoomImageList/${v}`);
  },
  //上架指定id图片
  publisSpecifiedIdQuery(v) {
    return myaxios.get(`/hotel/information/image/putOnImage/${v}`);
  },
  //上传图片给指定房型
  UpImageToSpRoomTypeBody(v) {
    return myaxios.post("/hotel/information/image/uploadImageToRoom", v);
  },
  //获取用户所管理的酒店id
  getHotelIds() {
    return myaxios.get(`/hotel/information/getHotelIds`);
  },
  //修改酒店营业状态
  updateHotelBusinessStatus(data) {
    return myaxios.post("/hotel/information/updateHotelBusinessStatus", data);
  },

  //查询所有业务类型电话
  getBusinessPhones(hotelId) {
    return myaxios.get(`/hotel/information/getBusinessPhones/${hotelId}`);
  },
  //增加电话
  addBusinessPhone(data) {
    return myaxios.post("/hotel/information/addBusinessPhone", data);
  },

  //修改电话
  updateBusinessPhone(data) {
    return myaxios.post("/hotel/information/updateBusinessPhone", data);
  },

  //删除电话
  deleteBusinessPhone(data) {
    return myaxios.post("/hotel/information/deleteBusinessPhone", data);
  },

  //简易入驻
  simpleAdd(data) {
    return myaxios.post("/hotel/Settle/simpleAdd", data);
  },
};
export default hotelApi;
