const state = {
  hotelId: "1828976582213857282",
  hotelDet: {},
};
const mutations = {
  SET_HOTEID(state, data) {
    state.hotelId = data;
  },
  SET_HOTELDET(state, data) {
    state.hotelDet = data;
  },
};

const actions = {
  setHoteId({ commit }, data) {
    commit("SET_HOTEID", data);
  },
  setHotelDet({ commit }, data) {
    commit("SET_HOTELDET", data);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
